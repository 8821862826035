<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="/assets/images/logo.png" alt="image" class="logo"></a>    
  </div>  
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [nbContextMenuTag]="tag"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
